async function getCustomClaim(claim) {
    await firebase.auth().currentUser.getIdToken(true);
    const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
    return decodedToken.claims[claim];
}


function makeRandom(length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function timeNiceName(value) {
    const niceNames = {
        min12: "12 Minute Plan",
        min16: "16 Minute Plan",
        min20: "20 Minute Plan",
        'min10b': '⚖️ 10 Minutes Balanced',
        'min12c': '🪷 12 Minutes Calming',
        'min12a': '⏰ 12 Minutes Alerting',
        'min16b': '⚖️ 16 Minutes Balanced',
        'min20a': '⏰ 20 Minutes Alerting',
        'min20c': '🪷 20 Minutes Calming'
    };
    return niceNames[value];
}

function senseTagName(value) {
    if (value.indexOf('Proprioception') > -1) {
        return 'Proprioception';
    }
    if (value.indexOf('Vestibular') > -1) {
        return 'Vestibular';
    }
    if (value.indexOf('Olfactory') > -1) {
        return 'Olfactory';
    }
    return value;
}

function logEvent(event, data) {
    db.ref("/users/")
        .child(sensePathApp.authData.uid)
        .child("events")
        .push({ event, data, timestamp: Date.now() });
}

const circuitIDs = [
    'min10b',
    'min12c',
    'min12a',
    'min16b',
    'min20a',
    'min20c'
];

function canAddProfiles() {
    if(sensePathApp.stripeRole === 'manualSubscription') {
        if(sensePathApp.profileLimit && sensePathApp.manualProfilesUsed) {
            if (sensePathApp.profileLimit > sensePathApp.manualProfilesUsed) {
                return true;
            }
            return false;
        }
    } else if(sensePathApp.profileLimit && sensePathApp.totalProfiles) {
        if (sensePathApp.profileLimit > sensePathApp.totalProfiles) {
            return true;
        }
        return false;
    }
}

function mustUpgrade() {
    if(sensePathApp.stripeRole === 'manualSubscription') {
        if(sensePathApp.profileLimit && sensePathApp.manualProfilesUsed) {
            if (sensePathApp.profileLimit <= sensePathApp.manualProfilesUsed) {
                return true;
            }
            return false;
        }
    } else if(sensePathApp.profileLimit && sensePathApp.totalProfiles) {
        if (sensePathApp.profileLimit <= sensePathApp.totalProfiles) {
            return true;
        }
        return false;
    }
}

function priceFilter(value) {
    // Reduce price by 33% if discounted
    if (sensePathApp.firstYearDiscount) {
        return '£' + ((value / 300) * 2).toFixed(0);
    }
    return '£' + (value / 100).toFixed(0);
}

function fetchManualSubscriptions(email) {
    return fetch('https://getmanualsubscriptionstatus-44kcfvg7kq-uc.a.run.app/?email=' + email)
        .then(resp => resp.json())
        .then(data => {
            if (data.profiles) {
                return data;
            } else {
                return null;
            }
        }).catch((error) => {
            // error
        });
}

async function getStripeRole(data) {
    try {
        const manualSubscription = await fetchManualSubscriptions(data.email);
        if (!manualSubscription) {
            getCustomClaim('stripeRole').then((stripeRole) => {
                if (!stripeRole) {
                    const announcementBar = document.querySelector(".announcement-bar");
                    if (announcementBar) {
                        announcementBar.style.display = "block";
                    }
                }
                sensePathApp.stripeRole = stripeRole;
                sensePathApp.profileLimit = Number(stripeRole ? stripeRole : 2);
            });
        } else {
            sensePathApp.profileLimit = manualSubscription.profiles;
            sensePathApp.manualProfilesUsed = manualSubscription.profilesUsed;
            sensePathApp.organisationName = manualSubscription.name;
            sensePathApp.manualSubExpiryDate = manualSubscription.expiryDate;
            sensePathApp.stripeRole = 'manualSubscription';

        }
    } catch (error) {
        console.error('Error:', error);
    }
}

async function getAdminRole() {
    try {
        return getCustomClaim('isAdmin').then((admin) => {
            sensePathApp.isAdmin = admin;
            return admin;
        });
    } catch (error) {
        console.error('Error:', error);
    }
}

function toggleLoading(trueOrFalse) {
    if (trueOrFalse) {
        document.querySelector("body").classList.add("loading");
    } else {
        document.querySelector("body").classList.remove("loading");
    }
}

function kebabCase(value) {
    return value.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .join('-')
        .toLowerCase();
}

function markdown(value) {
    return marked.parse(value);
}

function compare(a, b, prop) {
    if (a[prop] < b[prop]) {
        return -1;
    }
    if (a[prop] > b[prop]) {
        return 1;
    }
    return 0;
}

function randomizeArray(array) {
    return array.sort(() => Math.random() - 0.5);
}

function getCorrectProduct(){
    return sensePathApp.products.findIndex(product => product.profile_limit > sensePathApp.totalProfiles);
}